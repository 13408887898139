#projects {
	.grecaptcha-badge {
		display: none;
	}

	.section-projects-intro {
		background-color: #EEECE6;
		padding: 85px 0 10px 0;

		@include mq(tablet-down) {
			padding: 45px 0 0 0;
		}


		.sub-content {
			@extend %container;

			> h3 {
				color: #00303C;
				font-size: 26px;
				font-weight: $font-weight-semibold;
				text-align: center;
				text-transform: capitalize;

				@include mq(tablet-down) {
					font-size: 22px;
					line-height: 27px;
					letter-spacing: 0.5px;
					margin-bottom: 0;
					text-align: left;
				}
			}

			> p {
				font-size: 18px;
				font-weight: $font-weight-regular;
				line-height: 34px;
				margin-top: 25px;
				text-align: center;

				@include mq(tablet-down) {
					font-size: 14px;
					line-height: 27px;
					text-align: left;
					margin-bottom: 0;
				}
			}
		}
	}

	.section-projects {
		background-color: #EEECE6;
		padding-bottom: 90px;

		@include mq(tablet-down) {
			margin-bottom: 0;
			overflow: hidden;
		}


		.sub-content {
			@extend %container;

			display: flex;
			flex-direction: column;

			.list-projects {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;

				@include mq(tablet-down) {
					flex-direction: column;
					margin: 33px 0 0 0;
				}


				.item-project {
					cursor: pointer;
					margin: 5px;
					position: relative;
					width: calc((100% / 3) - 10px);

					@include mq(tablet-down) {
						align-items: flex-end;
						background-color: unset;
						margin-bottom: 17px;
						width: 100%;
					}


					@include mq(mobile) {
						height: 246px;
					}


					&--empty {
						@include mq(tablet-down) {
							display: none;
						}
					}

					.sub-image {
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						height: 274px;

						@include mq(mobile) {
							height: 246px;
						}
					}

					.sub-link {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 9;

						@include mq(tablet-down) {
							&:hover ~ .item-info svg {
								animation: arrow-anim-right 1s;
							}
						}
					}

					.item-info {
						background-color: rgba($color-text-title, 0.65);
						display: flex;
						flex-direction: column;
						height: 77px;
						justify-content: center;
						margin-top: -77px;
						padding: 0 25px;

						@include mq(tablet-down) {
							align-items: flex-start;
							background-color: rgba($color-primary, 0.6);
							display: flex;
							flex-direction: column;
							font-size: 18px;
							justify-content: center;
							letter-spacing: 0.54px;
							line-height: 16px;
							height: 65px;
							margin-bottom: 0;
							margin-top: -64px;
							max-width: unset;
							width: 100%;
						}


						p {
							color: $color-white;
							font-size: 12px;
							font-weight: $font-weight-thin;
							letter-spacing: 1.3px;
							margin-bottom: 2px;
							text-transform: uppercase;
						}

						h5 {
							align-items: center;
							color: $color-white;
							display: flex;
							font-size: 16px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0.8px;
							margin: 0;

							@include mq(tablet-down) {
								font-size: $font-size-med;
								margin-bottom: 0;
							}


							.btn-icon {
								display: flex;
								margin-left: auto;

								svg {
									color: #71898c;
								}
							}
						}
					}
				}

				.project-info-overlay {
					bottom: 0;
					display: none;
					height: 44px;
					left: 0;
					margin: 0;
					position: absolute;
					width: 100%;

					@include mq(tablet-down) {
						display: none;
					}


					a {
						align-items: center;
						background-color: rgba($color-white, 0.7);
						color: $color-primary;
						display: flex;
						font-size: 13px;
						font-weight: $font-weight-semibold;
						height: 44px;
						letter-spacing: 1.63px;
						padding-left: 25px;
						text-transform: uppercase;
						width: 100%;

						.btn-icon {
							.icon-container {
								> svg {
									color: #6e6f6d;
									margin: 7px 0 0 10px;
								}
							}
						}
					}
				}

				.item-project:hover .project-info-overlay {
					display: flex;

					@include mq(tablet-down) {
						display: none;
					}
				}

				.item-project:hover .item-info {
					display: none;

					@include mq(tablet-down) {
						display: flex;
					}
				}
			}

			.btn-primary {
				background: $color-white;
				border: 2.225px solid #d4d9db;
				border-radius: 5px;
				color: $color-primary;
				font-weight: $font-weight-bold;
				justify-content: center;
				margin: 73px auto 39px auto;
				padding: 15px 66px;
				text-align: center;
				text-transform: uppercase;
				width: auto;

				&:hover {
					color: rgba($color-primary, 0.5);
				}

				@include mq(tablet-down) {
					font-size: $font-size-small;
					margin: 60px auto 60px auto;
				}
			}

			.sub-project-filters {
				margin-bottom: 50px;

				@include mq(tablet-down) {
					margin: 0;
				}


				ul.list-filters {
					@extend %list-default;

					display: flex;
					justify-content: center;

					@include mq(tablet-down) {
						flex-wrap: wrap;
						justify-content: flex-start;
						margin-top: 30px;
					}


					li.item-filter {
						color: #76777A;
						cursor: pointer;
						font-size: 21px;
						font-weight: $font-weight-semibold;
						margin-left: 25px;

						@include mq(tablet-down) {
							font-size: 14px;
							margin-left: 0;
							margin-right: 20px;
						}


						&:after {
							content: '|';
							margin-left: 25px;

							@include mq(tablet-down) {
								margin-left: 20px;
								margin-right: 0;
							}
						}

						&:last-of-type {
							&::after {
								display: none;
							}
						}

						&--title {
							color: $color-primary;
							cursor: default;
							margin-left: 0;
							margin-right: 35px;

							@include mq(tablet-down) {
								margin-left: 0;
								margin-right: 20px;
							}


							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.section-other-sector {
		margin: 75px 0;
		overflow: hidden;

		@include mq(tablet-down) {
			margin: 50px 0;
		}


		@include mq(mobile) {
			margin: 60px 0 0 0;
		}


		.sub-content {
			@extend %container;

			position: relative;

			@include mq(tablet-down) {
				overflow: hidden;
			}


			div {
				overflow: unset;
			}

			h5 {
				align-items: center;
				color: $color-primary;
				display: flex;
				font-size: 20px;
				font-style: normal;
				font-weight: $font-weight-semibold;
				letter-spacing: 1.6px;
				margin-bottom: 20px;

				@include mq(tablet-down) {
					font-size: 18px;
					margin-bottom: 13px;
				}


				.btn {
					margin-left: auto;

					svg {
						color: #71898c;
					}
				}
			}

			.list-other-sectors {
				margin: 0;
				list-style-type: none;

				@include mq(tablet-down) {
					display: flex;
				}


				.slick-prev, .slick-next {
					color: $color-white;
					cursor: pointer;
					width: 24px;
					position: absolute;
					top: -39px;

					@include mq(tablet-down) {
						top: -30px;
					}
				}

				.slick-next {
					left: 210px;

					@include mq(tablet-down) {
						left: 216px;
					}
				}

				.slick-prev {
					left: 170px;

					@include mq(tablet-down) {
						left: 180px;
					}
				}

				.slick-disabled {
					cursor: default;
					opacity: 0.5;
				}

				.slick-slide {
					width: 395px;
				}

				.item-sector {
					align-items: center;
					background-color: rgba($color-primary, 0.6);
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					cursor: pointer;
					display: flex !important;
					height: 277px;
					justify-content: center;
					margin: 0;
					position: relative;

					@include mq(tablet-down) {
						align-items: flex-end;
						background-color: unset;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						margin-bottom: 11px;

						&:nth-child(n+5) {
							display: none;
						}
					}


					@include mq(mobile) {
						height: 199px;
					}


					&:hover {
						.item-information-overlay {
							background-color: rgba($color-white, 0.7);
						}

						.sector-title {
							color: $color-primary;
						}
					}

					> h5 {
						text-align: center;
					}

					.sub-link {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 99;
					}

					.item-information-overlay {
						background-color: $color-primary;
						display: flex;
						flex-direction: column;
						height: 277px;
						margin: 0;
						opacity: 0.6;
						padding: 30px 40px;
						position: absolute;
						width: 100%;

						@include mq(tablet-down) {
							display: none;
						}


						.project-info-sub-title {
							color: $color-white;
							font-size: 13px;
							letter-spacing: 1.3px;
							margin-bottom: 5px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								display: none;
							}
						}

						p {
							color: $color-white;
							font-size: $font-size-med;
							justify-self: flex-end;
							line-height: 29px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;

							@include mq(tablet-down) {
								line-height: $line-height-base;

								&:not(.project-info-sub-title) {
									display: none;
								}
							}
						}
					}

					.sector-title {
						color: $color-white;
						display: block;
						font-size: 19px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0.8px;
						margin: 0;
						position: relative;
						z-index: 9999;

						@include mq(tablet-down) {
							align-items: flex-start;
							background-color: rgba($color-primary, 0.6);
							display: flex;
							flex-direction: column;
							font-size: 18px;
							justify-content: center;
							letter-spacing: 0.54px;
							line-height: 16px;
							height: 65px;
							margin-bottom: 0;
							margin-top: -64px;
							max-width: unset;
							padding-left: 20px;
							width: 100%;
						}
					}

					.mod-show {
						display: flex;

						svg {
							animation: arrow-anim-right 1s;
						}
					}
				}
			}

			.sub-button-mobile {
				display: none;

				@include mq(tablet-down) {
					border: 1px solid #a7aba1;
					display: block;
					font-size: 12px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin: 53px auto;
					padding: 15px;
					text-align: center;
					text-transform: uppercase;
					width: 70%;
				}
			}
		}
	}
}
