#history {

	.grecaptcha-badge {
		display: none;
	}

	.header-main .sub-logo svg {
		color: $color-white;
	}

	.section-hero {
		align-items: center;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 100vh;
		justify-content: center;
		position: relative;
		text-align: center;

		@include mq(tablet-down) {
			margin-top: -1px;

			&:after {
				content: '';
				background: transparent;
				border: 1px solid $color-white;
				border-left: none;
				border-top: none;
				bottom: 40px;
				display: block;
				height: 12px;
				width: 12px;
				left: 50%;
				position: absolute;
				transform: rotate(45deg) translateX(-50%);
			}
		}

		h1 {
			color: $color-white;
			font-size: 55px;
			font-weight: $font-weight-semibold;
			margin-bottom: 8px;
			position: relative;
			z-index: 99;

			@include mq(tablet-down) {
				font-size: 25px;
			}
		}

		div {
			color: $color-white;
			font-weight: $font-weight-regular;
			font-size: 18px;
			line-height: 33px;
			margin: 0 auto;
			position: relative;
			width: 70%;
			z-index: 99;

			@include mq(tablet-down) {
				font-size: $font-size-med;
				max-width: 232px;
				width: 100%;
			}
		}
	}

	.section-timeline {
		background-color: $color-secondary;

		ul.list-timeline {
			margin: 0;

			li.sub-divider {
				list-style-type: none;

				&:before {
					background: $color-black;
					content: "";
					height: 82px;
					left: 50%;
					position: absolute;
					width: 1px;
					z-index: 2;

					@include mq(tablet-down) {
						height: 35px;
					}
				}
			}

			li.item-timeline-date {
				list-style-type: none;
				padding: 80px 0 55px 0;

				@include mq(tablet-down) {
					margin: 0;
					padding: 40px 15px 30px 15px;
				}

				h1 {
					color: #00303C;
					font-size: 65px;
					font-weight: $font-weight-light;
					letter-spacing: 1.8px;
					margin-bottom: 28px;
					margin-top: 20px;
					text-align: center;

					@include mq(tablet-down) {
						font-size: 30px;
						font-weight: $font-weight-light;
						line-height: 33px;
					}
				}

				p {
					color: #4A4A4A;
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 0;
					text-align: center;

					span {
						color: $color-text-title;
						cursor: pointer;
						font-weight: $font-weight-bold;
					}

					span:hover {
						text-decoration: underline;
					}
				}

				&:nth-last-child(1) {
					padding: 80px 0 80px 0;
				}
			}

			.section-sub-timeline {
				background: $color-white;
				padding: 75px 0 200px;
				position: relative;

				@include mq(tablet-down) {
					padding: 30px 0;
				}

				.sub-sub-timeline {
					@extend %container;

					max-width: 1050px !important;

					@include mq(tablet-down) {
						padding: 0 30px;
					}


					ul.list-sub-timeline {
						@extend %list-default;

						display: flex;
						flex-wrap: wrap;
						position: relative;
						align-items: center;

						&:before {
							background: $color-black;
							content: "";
							height: calc(100% - -423px);
							left: 50%;
							position: absolute;
							top: -146px;
							width: 1px;
							z-index: 2;

							@include mq(tablet-down) {
								display: none;
							}
						}

						&:after {
							background: $color-white;
							content: "";
							height: 72px;
							left: 50%;
							position: absolute;
							top: -147px;
							width: 1px;

							@include mq(tablet-down) {
								display: none;
							}
						}

						& > .item-marker {
							background: $color-white;
							border-radius: 50%;
							border: 1px solid $color-black;
							content: "";
							height: 30px;
							margin: auto;
							position: relative;
							width: 30px;
							z-index: 3;

							@include mq(tablet-down) {
								display: none;
							}
						}

						li.item-sub-timeline {
							flex-basis: calc(50% - 75px);
							flex-grow: 0;
							flex-shrink: 0;
							margin-bottom: 33px;
							position: relative;

							@include mq(tablet-down) {
								flex-basis: 100%;
								margin-bottom: 50px;

								&:after {
									content: '';
									background: transparent;
									border: 1px solid $color-primary;
									border-left: none;
									border-top: none;
									bottom: -30px;
									display: block;
									height: 12px;
									width: 12px;
									left: 50%;
									position: absolute;
									transform: rotate(45deg) translateX(-50%);
								}
							}

							&:last-child {
								margin-bottom: 0;

								&:after {
									display: none;
								}
							}

							&:nth-child(odd) {

								.wrap-content {
									display: flex;
									flex-direction: column;

									.sub-image {
										align-self: flex-end;

										@include mq(tablet-down) {
											align-self: center;
											//padding: 10px 0;
											order: 3;
											margin: 45px 0;
										}
									}

									.sub-content {
										padding: 0;
										text-align: right;

										p {
											line-height: 22px;
										}

										@include mq(tablet-down) {
											text-align: center;
											order: 2;
										}
									}

									.sub-title {
										position: relative;
										width: 100%;
										height: auto;
										padding: 0;

										h4 {
											float: right;
											text-align: right;

											@include mq(tablet-down) {
												font-size: 25px;
												max-width: none;
												text-align: center;
												margin: 15px auto;
											}
										}

										@include mq(tablet-down) {
											order: 1;
										}
									}
								}
							}

							&:nth-child(even) {
								margin-right: 0;
								margin-left: calc(50% + 75px);

								@include mq(tablet-down) {
									margin-left: 0;
								}


								.item-marker {
									right: auto;
									left: -91px;
								}

								&.mod-active .item-marker {
									left: -97px;
								}
							}

							.item-marker {
								content: "";
								background: $color-white;
								border-radius: 50%;
								border: 1px solid $color-primary;
								box-sizing: content-box;
								height: 30px;
								position: absolute;
								right: -91px;
								top: 50%;
								width: 30px;
								z-index: 3;

								&:before {
									background: $color-white;
									border-radius: 50%;
									border: 1px solid $color-primary;
									content: '';
									display: block;
									height: 18px;
									left: 50%;
									position: absolute;
									top: 50%;
									transform: translate(-50%, -50%);
									width: 18px;
								}

								@include mq(tablet-down) {
									display: none;
								}
							}

							&.mod-active .item-marker {
								width: 12px;
								height: 12px;
								border: 16px solid $color-black;
								top: 39px;
								right: -97px;
							}

							.wrap-content {
								padding: 12px 12px 30px;
								position: relative;

								@include mq(tablet-down) {
									padding: 0;
									display: flex;
									flex-direction: column;
								}
							}

							.sub-image {
								background-size: contain;
								background-position: center;
								background-repeat: no-repeat;
								height: 431px;
								width: 90%;
								margin-bottom: 20px;

								@include mq(tablet-down) {
									margin: 45px auto;
									order: 3;
									height: 359px;
									width: 90%;
								}
							}

							.sub-title {
								padding: 0 9px;

								h4 {
									margin-bottom: 0;
									font-size: 42px;
									color: $color-primary;
									max-width: 220px;
									width: 100%;

									@include mq(tablet-down) {
										font-size: 25px;
										max-width: none;
										text-align: center;
										margin: 15px auto;
									}
								}

								@include mq(tablet-down) {
									order: 1;
								}
							}

							.sub-content {
								color: #4A4A4A;
								font-size: $font-size-med;
								line-height: 24px;
								padding: 0 9px;

								@include mq(tablet-down) {
									font-size: $font-size-standard;
									text-align: center;
									order: 2;
								}

								p {
									font-size: 15px;
									line-height: 22px;
								}


								p:last-of-type {
									margin-bottom: 0;
								}
							}
						}
					}
				}

				.sub-more {
					@extend %container;

					margin-top: 275px;
					max-width: 700px !important;
					text-align: center;
					font-size: $font-size-large;
					color: $color-white;

					@include mq(tablet-down) {
						margin-top: 25px;
						font-size: $font-size-med;
					}


					h3 {
						font-size: $font-size-largest / $font-size-large + em;
						font-weight: $font-weight-bold;
						margin-bottom: 40px;
						color: $color-white;

						@include mq(tablet-down) {
							margin-bottom: 30px;
							font-size: $font-size-large / $font-size-med + em;
						}
					}
				}
			}
		}
	}
}