#corporate-information {
	font-family: $font-standard;

	.grecaptcha-badge {
		display: none;
	}

	.section-hero-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		display: flex;
		height: 902px;
		justify-content: flex-end;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}


		@include mq(mobile) {
			height: 283px;
		}


		.sub-content {
			align-content: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			margin: 0 80px 0 0;
			max-width: 615px;
			padding-top: 81px;
			width: 100%;
			z-index: 10;

			@include mq(tablet-down) {
				display: none;
			}


			h1 {
				color: $color-white;
				font-size: 25px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.42px;
				line-height: 30px;
				margin: 0 0 30px 0;
			}

			p {
				color: $color-white;
				font-size: 18px;
				font-weight: $font-weight-thin;
				letter-spacing: 0.37px;
				line-height: 33px;
				margin: 0;

				&:first-of-type {
					margin-bottom: 50px;
				}
			}

			&--mobile {
				@extend %container;

				display: none;
				padding: 45px 10px;
				z-index: 10;

				@include mq(tablet-down) {
					display: block;
				}


				> h1 {
					color: $color-white;
					font-size: 21px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0.5px;
					line-height: 27px;
				}
			}
		}

		.gradient-overlay {
			background: linear-gradient(270deg, #092D54, rgba(0, 28, 63, 0.62), rgba(24, 71, 116, 0.2));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.83;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-charity, .section-community {
		> .content--mobile {
			background-color: $color-white;
			padding: 30px 25px;
			display: none;

			@include mq(tablet-down) {
				display: block;
			}


			> p {
				font-size: 14px;
				letter-spacing: 0.29px;
				line-height: 27px;
			}

			> h3 {
				font-size: 21px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.5px;
				margin-bottom: 2px;
			}
		}

		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;
			padding: 100px 0 91px 0;

			@include mq(tablet-down) {
				flex-direction: column;
				padding: 0;
			}


			.sub-copy-left, .sub-copy-right {
				display: flex;
				flex-direction: column;
				width: 47%;

				@include mq(tablet-down) {
					margin-bottom: 30px;
					width: 100%;
				}


				> h1 {
					color: $color-primary;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					line-height: 20px;

					@include mq(tablet-down) {
						font-size: 21px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0.5px;
						margin-bottom: 20px;
					}
				}

				> p {
					color: $color-black;
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;
					margin: 0 0 30px 0;
					max-width: 90%;

					@include mq(tablet-down) {
						font-size: 14px;
						letter-spacing: 0.29px;
						line-height: 27px;
						max-width: 100%;
					}


					&:last-child {
						margin-bottom: 0;
						max-width: 100%;
					}

					> img {
						margin-top: 10px;
						width: 100% !important;
					}
				}

				> div {
					margin: auto 0 0 0;

					> img {
						@include mq(tablet-down) {
							height: auto;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.section-community {
		background-color: $color-secondary;

		.sub-content {
			@include mq(tablet-down) {
				padding: 45px 0 0 0;
			}
		}
	}

	.section-values, .section-behavioural-safety {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: flex-end;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}


		@include mq(mobile) {
			height: 283px;
		}


		.sub-content {
			@extend %container;

			align-content: center;
			display: flex;
			justify-content: space-between;
			padding: 100px 0 60px 0;
			z-index: 10;

			@include mq(tablet-down) {
				padding: 0;
			}


			.sub-col-left, .sub-col-right {
				display: flex;
				flex-direction: column;
				width: 60%;

				> h1 {
					color: $color-white;
					font-size: 25px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0.42px;
					line-height: 30px;
					margin: 0 0 30px 0;
				}

				> p {
					color: $color-white;
					font-size: 18px;
					font-weight: $font-weight-thin;
					letter-spacing: 0.37px;
					line-height: 33px;
					margin: 0;
				}

				> ul {
					@extend %list-default;
				}

				> ul li {
					color: $color-white;
					font-size: 23px;
					font-weight: $font-weight-thin;
					line-height: 43px;
					list-style-type: none;
					margin: 0;

					> strong {
						font-weight: $font-weight-regular;
					}
				}
			}

			.sub-col-right {
				align-items: center;
				justify-content: center;
				width: 40%;

				@include mq(tablet-down) {
					width: 100%;
				}


				> img {
					@include mq(tablet-down) {
						height: auto;
						width: 276.35px;
					}
				}
			}
		}

		.gradient-overlay {
			background: linear-gradient(270deg, #092D54, rgba(0, 28, 63, 0.62), rgba(24, 71, 116, 0.2));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.83;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-values--mobile {
		background-color: $color-white;
		padding: 30px 25px;
		display: none;

		@include mq(tablet-down) {
			display: block;
		}


		.sub-content {
			@extend %container;

			> p {
				display: none;
			}

			> h1 {
				font-size: 21px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.5px;
				margin-bottom: 20px;
			}

			> ul {
				@extend %list-default;

				> li {
					list-style-type: none;
					margin: 0;
				}
			}
		}
	}

	.section-values {
		background-attachment: fixed;

		.sub-content {
			.sub-col-left {
				@include mq(tablet-down) {
					display: none;
				}
			}

			.sub-col-right {
				> p {
					display: none;
				}

				> h1 {
					display: none;
				}
			}
		}
	}

	.section-health-safety {
		background-color: $color-secondary;

		.sub-content {
			@extend %container;

			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 100px 0 91px 0;

			@include mq(tablet-down) {
				padding: 40px 0 70px 0;
			}


			> h1 {
				color: $color-primary;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				line-height: 20px;

				@include mq(tablet-down) {
					font-size: 21px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0.5px;
					margin-bottom: 20px;
				}
			}

			.sub-intro {
				align-items: flex-end;
				display: flex;
				justify-content: space-between;
				margin-bottom: 40px;
				width: 100%;

				@include mq(tablet-down) {
					flex-wrap: wrap;
					justify-content: unset;
				}


				> p {
					color: $color-black;
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;
					margin: 0;
					max-width: 65%;

					@include mq(tablet-down) {
						font-size: 14px;
						letter-spacing: 0.29px;
						line-height: 27px;
						margin-bottom: 40px;
						max-width: unset;
						width: 100%;
					}
				}
			}

			.sub-copy-left, .sub-copy-right {
				display: flex;
				flex-direction: column;
				width: 47%;

				@include mq(tablet-down) {
					width: 100%;
				}


				> h1 {
					color: $color-primary;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					line-height: 20px;

					@include mq(tablet-down) {
						font-size: 21px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0.5px;
						margin-bottom: 20px;
					}
				}

				> p {
					color: $color-black;
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;
					margin: 0 0 30px 0;
					max-width: 90%;

					@include mq(tablet-down) {
						font-size: 14px;
						letter-spacing: 0.29px;
						line-height: 27px;
						margin-bottom: 30px;
						max-width: unset;
						width: 100%;
					}


					&:last-child {
						margin-bottom: 0;
						max-width: 100%;
					}
				}

				> div {
					margin: auto 0 0 0;
				}
			}
		}
	}

	.section-construction {
		.sub-content {
			@extend %container;

			align-content: center;
			display: flex;
			justify-content: space-between;
			padding: 100px 0 60px 0;
			z-index: 10;

			@include mq(tablet-down) {
				flex-direction: column-reverse;
				padding: 30px 0 50px 0;
			}


			.sub-col-left, .sub-col-right {
				display: flex;
				flex-direction: column;
				width: 60%;

				@include mq(tablet-down) {
					width: 100%;
				}


				> h1 {
					color: $color-primary;
					font-size: 25px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0.42px;
					line-height: 30px;
					margin: 0 0 30px 0;

					@include mq(tablet-down) {
						font-size: 22px;
						line-height: 27px;
						letter-spacing: 0.5px;
						margin-bottom: 23px;
						text-align: left;
					}
				}

				> p {
					color: $color-black;
					font-size: 18px;
					font-weight: $font-weight-thin;
					letter-spacing: 0.37px;
					line-height: 33px;
					margin: 0;

					@include mq(tablet-down) {
						font-size: 14px;
						line-height: 27px;
						text-align: left;
					}
				}

				> ul {
					@extend %list-default;
				}

				> ul li {
					color: $color-white;
					font-size: 23px;
					font-weight: $font-weight-thin;
					line-height: 43px;
					list-style-type: none;
					margin: 0;

					> strong {
						font-weight: $font-weight-regular;
					}
				}
			}

			.sub-col-right {
				align-items: center;
				justify-content: center;
				width: 40%;

				@include mq(tablet-down) {
					margin-bottom: 30px;
					width: 100%;
				}


				> div {
					> img {
						@include mq(tablet-down) {
							height: 347px;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.section-behavioural-safety {
		background-attachment: fixed;

		.sub-content {
			padding: 199px 0 220px 0;

			@include mq(tablet-down) {
				padding: 0;
			}


			.sub-col-left {
				width: 55%;

				@include mq(tablet-down) {
					display: none;
				}


				> p {
					margin-bottom: 30px;

					&:last-of-type {
						margin: 0;
					}
				}
			}

			.sub-col-right {
				> img {
					@include mq(tablet-down) {
						height: 232px;
					}
				}
			}
		}
	}

	.section-quality-block {
		background-color: $color-secondary;

		.sub-content {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column-reverse;
				justify-content: unset;
			}


			.sub-main-copy {
				padding: 88px 120px 100px 160px;
				width: calc((100% / 3) * 2);

				@include mq(tablet-down) {
					height: 100%;
					padding: 30px 15px 50px 15px;
					width: 100%;
				}


				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					max-width: 512px;

					@include mq(mobile) {
						font-size: 20px;
					}
				}

				p {
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;

					@include mq(mobile) {
						font-size: 14px;
						max-width: unset;
					}


					strong {
						color: $color-text-title;
						font-weight: $font-weight-bold;
					}
				}

				a {
					font-size: 16px;
					font-weight: $font-weight-bold;
					letter-spacing: 1.8px;

					@include mq(tablet-down) {
						font-size: 14px;
					}
				}
			}

			.sub-content-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				width: calc(100% / 3);

				@include mq(mobile) {
					height: 288px;
					width: 100%;
				}
			}
		}
	}
}
