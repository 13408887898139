#special-projects {

	.grecaptcha-badge {
		display: none;
	}

	.section-main-copy {
		margin-top: 60px;

		@include mq(tablet-down) {
			margin-top: 40px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-copy-wrapper {
				flex: 1;
				max-width: 613px;
				width: 100%;

				@extend %common-two-col-layout;
			}

			.sub-stats {
				@extend %stats;
			}
		}
	}

	.section-image-full {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 536px;
		margin-top: 80px;

		@include mq(tablet-down) {
			display: none;
		}
	}

	.section-case-study {
		background-color: $color-secondary;

		.sub-content {
			align-content: center;
			display: flex;
			height: 100%;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
			}

			.sub-main-copy {
				flex: 1;
				min-height: 600px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@include mq(tablet-down) {
					min-height: auto;
					padding: 88px 21px;
				}


				.sub-copy {
					padding: 70px 120px;

					@include mq(tablet-down) {
						padding: 0;
					}


					h2 {
						color: $color-text-title;
						font-size: 25px;
						font-weight: $font-weight-bold;
						letter-spacing: 0.6px;
						max-width: 512px;

						@include mq(tablet-down) {
							font-size: 20px;
							max-width: none;
						}
					}

					p {
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 33px;

						@include mq(tablet-down) {
							font-size: 14px;
							max-width: none;
						}
					}

					a {
						align-items: center;
						display: flex;
						font-size: 13px;
						font-weight: $font-weight-bold;
						letter-spacing: 1.8px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							font-size: 11px;
						}


						.btn {
							display: flex;
							margin-left: 20px;

							svg {
								color: #71898c;
							}
						}

						&:hover {
							svg {
								animation: arrow-anim-right 1s;
							}
						}
					}
				}
			}

			.sub-content-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				max-width: 540px;
				width: 100%;

				@include mq(tablet-down) {
					height: 260px;
					max-width: none;
				}
			}
		}

		&.mod-content-block-reverse {
			background-color: $color-white;

			.sub-content {
				flex-direction: row-reverse;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				.sub-content-image {
					@include mq(tablet-down) {
						background-size: unset;
						order: 1;
					}
				}
			}
		}
	}
}
