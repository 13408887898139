.section-cookies {
	align-items: center;
	background: $color-primary;
	bottom: 0;
	display: flex;
	padding: 20px 0;
	position: fixed;
	width: 100%;
	z-index: 9999;

	@media screen and (max-width: 1272px) {
		height: auto;
		padding: 10px 0 20px;
	}

	&.mod-hide {
		display: none;
	}

	.sub-content {
		@extend %container-large;

		display: flex;

		@media screen and (max-width: 1272px) {
			flex-direction: column;
		}

		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: $font-size-small;
				line-height: $line-height-base;
				margin-bottom: 0;

				@media screen and (max-width: 1272px) {
					color: rgba($color-white, 0.7);
				}

				a {
					color: $color-white;
					font-weight: $font-weight-semibold;

					@media screen and (max-width: 1272px) {
						color: rgba($color-white, 0.7);
					}
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@media screen and (max-width: 1272px) {
				margin-left: 0;
				margin-top: 20px;
			}

			.btn {
				background: none;
				border: 2px solid rgba($color-white, 0.25);
				color: $color-white;
				display: inline-block;
				font-size: $font-size-standard;
				font-family: $headings-font !important;
				padding: 5px 0;
				transition: background .3s ease;
				width: 130px;

				@media screen and (max-width: 1272px) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}

				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}

.header-main {
	background-color: unset;
	height: 144px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: 64px;
	}


	&.mod-white {
		background-color: $color-white;
		position: relative;
	}

	.sub-container {
		align-items: center;
		display: flex;
		flex-wrap: unset;
		height: 100%;
		justify-content: space-between;
		padding: 0 65px;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			padding: 0px 20px;
		}


		.sub-logo {
			align-items: flex-end;
			height: 47px;
			z-index: 999;

			@include mq(tablet-down) {
				height: 28.23px;
				left: 18px;
				top: 20px;
				width: 190px;
			}


			a {
				display: block;
				z-index: 999;

				@include mq(tablet-down) {
					width: 190px;
					height: 28.23px;
				}


				svg {
					color: $color-primary;
					height: 46px;
					width: 172px;

					@include mq(tablet-down) {
						width: 120px;
						height: 28.23px;
					}
				}
			}
		}

		.mob-hamburger {
			align-items: center;
			cursor: pointer;
			display: flex;
			justify-content: flex-end;
			right: 55px;
			transition: width .3s ease;
			z-index: 999;

			@include mq(tablet-down) {
				right: 25px;
				top: 34px;
			}


			span {
				color: $color-primary;
				font-size: 13px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.86px;
				margin-right: 22px;

				@include mq(tablet-down) {
					display: none;
				}
			}

			.menu-icon-wrapper {
				height: 28px;
				width: 35px;

				.mob-bar {
					background: $color-primary;
					border-radius: 20px;
					display: block;
					height: 2px;
					margin-bottom: 10px;
					opacity: 1;
					transform: rotate(0deg);
					transition: .4s ease-in-out;
					width: 35px;
					z-index: 99999;

					@include mq(tablet-down) {
						margin-bottom: 6px;
						width: 23px;
					}
				}

				.mob-bar-3 {
					margin-bottom: 0;
					width: 18px;
				}
			}

			&.mod-active {
				height: 25px;
				position: fixed;
				top: 55px;
				width: 25;
				z-index: 99;

				@include mq(tablet-down) {
					top: 24px;
				}


				.mob-bar {
					background: $color-primary;
				}

				.mob-bar-1 {
					top: 10px;
					position: absolute;
					right: 0;
					transform: rotate(135deg);
					width: 35px;
				}

				.mob-bar-2 {
					display: none;
					left: -60px;
					opacity: 0;
				}

				.mob-bar-3 {
					transform: rotate(-135deg);
					top: 10px;
					position: absolute;
					right: 0;
					width: 35px;
				}

				& + #nav {
					visibility: visible;
				}

				span {
					display: none;
				}
			}
		}

		.mod-white {
			span {
				color: $color-white;
			}

			.menu-icon-wrapper {
				z-index: 2;

				.mob-bar {
					background: $color-white;
				}
			}

			&.mod-active {
				z-index: 999999;

				.mob-bar {
					background: $color-primary;
				}
			}
		}
	}

	nav {
		align-items: center;
		background: $color-white;
		display: none;
		filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.1));
		height: 100%;
		justify-self: flex-end;
		margin-left: auto;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		width: 340px;

		@include mq(tablet-down) {
			right: unset;
			width: 100vw;
		}


		&.mod-active {
			display: flex;
			position: fixed;
			z-index: 9;
		}

		.list-nav {
			@extend %list-default;

			background-color: $color-white;
			border-left: 1px solid $color-neutral;
			flex-direction: column;
			height: 100%;
			overflow-y: scroll;
			padding: 97px 56px 0 22px;
			position: relative;
			width: 340px;

			@include mq(tablet-down) {
				border: none;
				overflow-y: scroll;
				padding: 75px 20px 0 20px;
				width: 100%;
			}


			li {
				@extend %list-default;

				border-bottom: 0.75px solid $color-borders-light;
				clear: both;
				flex-direction: column;
				text-align: left;
				position: relative;
				width: 100%;

				&:last-child {
					margin-bottom: 120px;
				}

				&.mod-drop {
					.mod-dropdown-active {
						svg {
							color: $color-primary;
							transform-origin: center;
							transform: rotate(-180deg);
						}
					}

					& > a {
						align-items: center;
						color: $color-primary;
						display: flex;
						font-size: 18px;
						justify-content: space-between;
						letter-spacing: 0.6px;
						padding: 21px 0;
						position: relative;
						text-transform: capitalize;

						@include mq(tablet-down) {
							font-size: 20px;
						}


						& > svg {
							display: block;
							color: $color-primary;
						}

						&:hover {
							color: $color-link-hover;
						}
					}
				}

				& > a {
					align-items: center;
					color: $color-primary;
					display: flex;
					font-size: 18px;
					justify-content: space-between;
					letter-spacing: 0.6px;
					padding: 16px 20px 16px 0;
					position: relative;
					text-transform: capitalize;

					&:hover {
						color: $color-link-hover;
					}

					@include mq(tablet-down) {
						font-size: 20px;
						padding: 16px 0;
					}
				}

				a {
					& > svg {
						color: $color-primary;
						display: none;
						height: 8px;
						margin-left: 15px;
						stroke: $color-primary;
						transition: all 270ms ease-out;
						width: 11px;
					}

					&:hover {
						color: $color-link-hover;
					}
				}

				& > .sub-dropdown-wrap {
					@extend %list-default;

					display: none;

					& li {
						@extend %list-default;

						border-bottom: 0;
						padding: 0;

						& a {
							font-family: $font-standard;
							font-size: 16px;
							font-weight: $font-weight-light;
							justify-content: space-between;
							line-height: 20px;
							padding-bottom: 0;
							padding-top: 25px;
						}

						&:last-child {
							padding: 0 0 45px 0;
						}
					}

					&.mod-active {
						display: block;

						& > .list-sub-nav {
							margin: 0;

							& > .item-nav {
								& a {
									font-family: $font-standard;
									font-size: 15px;
									font-weight: $font-weight-light;
									justify-content: space-between;
									line-height: 20px;
									padding-bottom: 0;
									padding-right: 0;
									padding-top: 20px;

									& svg {
										display: block;
										height: 8px;
										margin-left: 15px;
										position: relative;
										right: 0;
										stroke: $color-primary;
										transition: all 270ms ease-out;
										transform: rotate(-90deg);
										width: 18px;
									}
								}
							}
						}
					}
				}
			}

			.active {
				a {
					//text-decoration: underline;
			
				}
			}
		}
	}
}
