#news {

	.grecaptcha-badge {
		display: none;
	}

	.wrap-list {
		@extend %container;
	}

	.section-blog-list {
		padding: 70px 0;

		@include mq(tablet-down) {
			padding: 30px 0 70px 0;
		}


		header {
			h1 {
				margin: 0 0 65px 0;
				font-size: 25px;
				font-weight: $font-weight-bold;
				color: #14242D;

				@include mq(tablet-down) {
					color: $color-text-title;
					font-size: 20px;
					font-weight: $font-weight-bold;
					margin: 0 0 20px 0;
				}
			}
		}

		.sub-button-mobile {
			display: none;

			@include mq(tablet-down) {
				border: 1px solid #a7aba1;
				display: block;
				font-size: 12px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				margin: 53px auto;
				padding: 15px;
				text-align: center;
				text-transform: uppercase;
				width: 70%;
			}
		}
	}
}
