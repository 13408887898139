#people-careers {
	font-family: $font-standard;

	.grecaptcha-badge {
		display: none;
	}

	.section-intro {
		background-color: $color-secondary;

		.sub-content {
			@extend %container;
			padding: 60px 0;

			> p {
				color: $color-black;
				font-size: 18px;
				font-weight: $font-weight-regular;
				line-height: 33px;
				margin: 0 0 30px 0;

				@include mq(tablet-down) {
					font-size: 14px;
					letter-spacing: 0;
					line-height: 30px;
				}

				&:first-of-type {
					font-weight: $font-weight-bold;
				}

				&:last-of-type {

					@include mq(tablet-down) {
						margin-bottom: 0
					}
				}
			}
		}
	}

	.section-team {

		.sub-content {
			@extend %container;
			padding: 73px 0 127px 0;

			@include mq(tablet-down) {
				padding: 30px 0 50px 0;
			}

			> h1 {
				color: $color-primary;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				line-height: 20px;
				margin-bottom: 30px;
			}

			> p {
				color: $color-black;
				font-size: 18px;
				font-weight: $font-weight-regular;
				line-height: 33px;
				margin: 0 0 40px 0;
			}

			.list-team-members {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				list-style-type: none;
				margin: 0;
				position: relative;

				.item-team-member {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					height: 331px;
					margin: 0 0 30px 0;
					overflow: hidden;
					position: relative;
					width: calc((100% / 3) - (20px / 3));

					@include mq(tablet-down) {
						height: 303px;
						margin-bottom: 11px;
						overflow: unset;
						position: relative;
						width: 100%;
					}

					&--extra {

						@include mq(tablet-down) {
							display: none;
						}
					}

					.team-info {
						background-color: rgba(#0A2240, 0.65);
						bottom: 0;
						display: flex;
						flex-direction: column;
						justify-content: center;
						left: 0;
						height: 93px;
						padding: 20px 25px;
						position: absolute;
						width: 100%;

						p {
							color: $color-white;
							font-size: 13px;
							font-weight: $font-weight-thin;
							letter-spacing: 1.6px;
							line-height: 14px;
							margin-bottom: 8px;
							text-transform: uppercase;
						}

						h5 {
							color: $color-white;
							font-size: 20px;
							font-weight: $font-weight-semibold;
							line-height: 16px;
							margin: 0;

							@include mq(tablet-down) {
								font-size: 17px;
							}
						}
					}
				}
			}
		}
	}

	.section-banner {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		.sub-content {
			@extend %container;
			display: flex;
			justify-content: space-between;
			padding: 67px 0 56px 0;

			@include mq(tablet-down) {
				flex-direction: column-reverse;
				padding: 60px 0;
			}

			.sub-image {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 50%;
				height: 252px;
				width: 252px;

				@include mq(tablet-down) {
					margin: 55px auto 0 auto
				}
			}

			.sub-text {
				width: 72%;

				@include mq(tablet-down) {
					width: 100%;
				}

				> p {
					color: $color-white;
					font-size: 18px;
					font-weight: $font-weight-thin;
					line-height: 33px;
					margin: 0 0 30px 0;

					@include mq(tablet-down) {
						font-size: 14px;
						letter-spacing: 0;
						line-height: 27px;
					}

					&:last-of-type {
						font-weight: $font-weight-regular;
					}
				}

				> a {
					color: $color-white;
					font-size: 14px;
					font-weight: $font-weight-regular;
					line-height: 16px;
					letter-spacing: 1.63px;
					text-transform: uppercase;

					@include mq(tablet-down) {
						font-size: 13px;
						letter-spacing: 0.5px;
					}

					> svg {
						color: $color-white;
						margin: 0 0 0 10px;
					}
				}
			}
		}
	}

	.section-life-at-kingerlee {
		background-color: $color-secondary;

		.sub-content {
			@extend %container;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 125px 0 35px 0;

			@include mq(tablet-down) {
				flex-direction: column;
				padding: 30px 0 50px 0;
			}

			> h1 {
				color: $color-primary;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				line-height: 20px;
				margin-bottom: 30px;
				width: 100%;

				@include mq(tablet-down) {
					font-size: 21px;
					letter-spacing: 0.5px;
					line-height: 20px;
				}
			}

			.sub-col-left, .sub-col-right {
				display: flex;
				flex-direction: column;
				width: 47%;

				@include mq(tablet-down) {
					width: 100%;
				}

				> h1 {
					color: $color-primary;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					line-height: 20px;
				}

				> p {
					color: $color-black;
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;
					margin: 0 0 30px 0;
					max-width: 90%;

					@include mq(tablet-down) {
						font-size: 14px;
						letter-spacing: 0;
						line-height: 27px;
						max-width: unset;
					}

					&:last-child {
						margin-bottom: 0;
						max-width: 100%;
					}
				}

				> img {
					border-radius: 50%;
					height: 218px;
					width: 218px;

					@include mq(tablet-down) {
						margin: 0 auto;
					}
				}
			}

			.sub-col-left {

				> p {

					&:first-child {
						font-weight: $font-weight-bold;
					}
				}
			}

			.sub-col-right {

				> p {
					font-style: italic;
				}

				.testimonial-name {
					color: #00303C;
					font-size: 12px;
					font-style: normal;
					font-weight: $font-weight-bold;
					letter-spacing: 1.47px;
					line-height: 33px;
					text-transform: uppercase;
				}
			}
		}
	}

	.section-employee-benefits {

		.sub-content {
			@extend %container;
			padding: 111px 0 106px 0;
			max-width: 990px;

			@include mq(tablet-down) {
				padding: 50px 15px 0 15px;
			}

			> h1 {
				color: $color-primary;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				line-height: 20px;
				margin-bottom: 70px;
				text-align: center;
				width: 100%;

				@include mq(tablet-down) {
					font-size: 21px;
					font-weight: bold;
					letter-spacing: 0.5px;
					line-height: 20px;
					text-align: left
				}
			}

			ul.list-employee-benefits {
				@extend %list-default;
				display: flex;
				flex-wrap: wrap;

				@include mq(tablet-down) {
					justify-content: space-evenly;
				}

				li.item-employee-benefit {
					align-items: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-bottom: 40px;
					width: calc(100% / 5);

					@include mq(tablet-down) {
						width: calc(100% / 3);
					}

					>img {
						margin-bottom: 20px;
					}

					> p {
						color: $color-primary;
						font-size: 14px;
						line-height: 19px;
						text-align: center;
						margin: 0;
						max-width: 58%;

						@include mq(tablet-down) {
							max-width: 85%;
						}
					}
				}
			}
		}
	}

	.section-values {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		.gradient-overlay {
			background: linear-gradient(270deg,#092D54,rgba(0,28,63,0.62), rgba(24,71,116,0.2));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.83;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}

		.sub-content {
			@extend %container;
			display: flex;
			justify-content: space-between;
			padding: 130px 0 110px 0;
			position: relative;
			z-index: 11;

			@include mq(tablet-down) {
				padding: 50px 0;
			}

			.sub-col-left, .sub-col-right {
				display: flex;
				width: 47%;

				> h1 {
					color: $color-white;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					line-height: 20px;
				}

				> p {
					color: $color-white;
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;
					margin: 0;

					&:last-child {
						margin-bottom: 0;
						max-width: 100%;
					}
				}

				> ul {
					@extend %list-default;

					> li {
						color: $color-white;
						font-size: 18px;
						font-weight: $font-weight-regular;
						line-height: 33px;
						margin: 0;
					}
				}
			}

			.sub-col-left {
				flex-direction: column;
				justify-content: center;
				width: 47%;

				@include mq(tablet-down) {
					display: none;
				}
			}

			.sub-col-right {
				flex-wrap: wrap;
				justify-content: space-evenly;
				width: 53%;

				@include mq(tablet-down) {
					width: 100%;
				}

				> div {
					width: calc(100% / 3);

					@include mq(tablet-down) {
						height: 100px;
						width: 100px;
					}

					> img {

						@include mq(tablet-down) {
							height: 100px;
							width: 100px;
						}
					}
				}
			}
		}
	}

	.section-values--mobile {
		background-color: $color-white;
		padding: 30px 25px;
		display: none;

		@include mq(tablet-down) {
			display: block;
		}

		.sub-content {
			@extend %container;

			@include mq(tablet-down) {
				margin: 0;
			}

			> p {
				display: none;
			}

			> h1 {
				font-size: 21px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.5px;
				margin-bottom: 20px;
			}

			> ul {
				@extend %list-default;

				> li {
					list-style-type: none;
					margin: 0;
				}
			}
		}
	}

	.section-current-vacancies {
		margin-top: 80px;

		@include mq(tablet-down) {
			margin-top: 0;
		}

		.sub-content {
			@extend %container;

			@include mq(tablet-down) {
				padding: 20px 0 50px 0;
			}

			h2 {
				color: $color-text-title;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				margin-bottom: 34px;
				max-width: 512px;

				@include mq(tablet-down) {
					font-size: 21px;
					letter-spacing: 0.5px;
					line-height: 20px;
				}
			}

			.list-vacancies {
				@extend %list-default;

				.item-vacancy {
					background-color: $color-secondary;
					display: flex;
					height: 190px;
					margin-bottom: 10px;
					padding: 30px;
					width: 100%;

					@include mq(tablet-down) {
						height: unset;
						flex-wrap: wrap;
					}

					.vacancy-details-wrapper {
						display: flex;
						flex-direction: column;
						height: 100%;
						justify-content: center;
						width: 80%;

						@include mq(tablet-down) {
							flex-wrap: wrap;
							width: 100%;
						}

						p {
							color: #686868;
							font-size: 17px;
							font-weight: $font-weight-thin;
							line-height: 28px;
							margin: 15px 0 0 0;
						}

						.vacancy-details {

							@include mq(tablet-down) {
								width: 100%;
							}

							h1 {
								color: $color-text-title;
								font-size: 24px;
								font-weight: $font-weight-semibold;
								margin-bottom: 8px;
							}

							span {
								color: $color-text-title;
								font-size: 15px;
								letter-spacing: 0.25px;

								&:not(:last-child):after {
									color: #686868;
									content: '|';
									padding: 0 10px;
								}
							}
						}
					}

					.vacancy-button-wrapper {
						align-items: center;
						display: flex;
						justify-content: flex-end;
						width: 20%;

						@include mq(tablet-down) {
							width: 100%;
						}

						a {
							background-color: $color-white;
							color: $color-text-title;
							display: block;
							font-size: 13px;
							font-weight: $font-weight-semibold;
							letter-spacing: 1.6px;
							line-height: 17px;
							padding: 16px 23px;
							text-align: left;
							text-transform: uppercase;
							width: 174px;

							@include mq(tablet-down) {
								margin-top: 20px;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}