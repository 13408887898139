#about {
	.section-hero-image, .section-footer-image {
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: flex-end;
		padding: 83px 0;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}


		@include mq(mobile) {
			height: 283px;
		}


		.sub-content {
			align-content: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			margin: 0 80px 0 0;
			width: 50%;
			z-index: 10;

			@include mq(tablet-down) {
				display: none;
			}


			&--mobile {
				@extend %container;

				display: none;
				padding: 45px 10px;
				z-index: 10;

				@include mq(tablet-down) {
					display: block;
				}


				> h1 {
					color: $color-white;
					font-size: 21px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0.5px;
					line-height: 27px;
				}
			}

			h1 {
				color: $color-white;
				font-size: 25px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.42px;
				line-height: 30px;
				margin: 0;
				width: 50%;
			}

			h3 {
				color: $color-white;
				font-size: 25px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.42px;
				line-height: 30px;
				margin: 0;
				width: 50%;
			}

			p {
				color: $color-white;
				font-size: 18px;
				font-weight: $font-weight-thin;
				letter-spacing: 0.37px;
				line-height: 33px;
				margin: 0;
			}
		}

		.gradient-overlay {
			background: linear-gradient(270deg, #092D54, rgba(0, 28, 63, 0.62), rgba(24, 71, 116, 0.2));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.83;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-history-steps {
		background: $color-secondary;
		padding: 50px 0 200px;
		position: relative;

		@include mq(tablet-down) {
			padding: 0;
		}


		> .content--mobile {
			background-color: $color-white;
			padding: 30px 25px;
			display: none;

			@include mq(tablet-down) {
				display: block;
			}


			> p {
				font-size: 14px;
				letter-spacing: 0.29px;
				line-height: 27px;
			}

			> h3 {
				font-size: 21px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0.5px;
				margin-bottom: 2px;
			}
		}

		.sub-content {
			@extend %container;

			max-width: 707px !important;

			@include mq(tablet-down) {
				max-width: unset;
				padding: 40px 25px;
			}


			> h2 {
				color: $color-primary;
				font-size: 25px;
				font-weight: $font-weight-bold;
				text-align: center;

				@include mq(tablet-down) {
					font-size: 21px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0.39px;
					line-height: 20px;
					text-align: center;
					margin-bottom: 12px;
				}
			}

			> h2.sub-title {
				margin-bottom: 80px;

				@include mq(tablet-down) {
					display: none;
				}
			}

			.sub-intro {
				margin-bottom: 80px;

				> p {
					color: $color-black;
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;
					margin: 0;

					@include mq(tablet-down) {
						font-size: 14px;
						letter-spacing: 0;
						line-height: 27px;
						text-align: center;
						margin-bottom: 30px;
					}
				}
			}

			.sub-divider {
				display: none;

				@include mq(tablet-down) {
					background-color: $color-primary;
					display: block;
					height: 2px;
					margin: 0 auto 30px auto;
					width: 35px;
				}
			}

			ul.list-history-steps {
				@extend %list-default;

				align-items: center;
				display: flex;
				flex-wrap: wrap;
				position: relative;

				&:before {
					background: $color-black;
					content: "";
					height: calc(100% - -206px);
					left: 50%;
					position: absolute;
					top: 0;
					width: 1px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				& > .item-marker {
					background: $color-white;
					border-radius: 50%;
					border: 1px solid $color-black;
					content: "";
					height: 30px;
					margin: auto;
					position: relative;
					width: 30px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				li.item-history-step {
					flex-basis: calc(50% - 75px);
					flex-grow: 0;
					flex-shrink: 0;
					margin-bottom: 33px;
					position: relative;

					@include mq(tablet-down) {
						flex-basis: 100%;
						margin-bottom: 43px;

						&:after {
							content: '';
							background: transparent;
							border: 1px solid $color-primary;
							border-left: none;
							border-top: none;
							bottom: -30px;
							display: block;
							height: 12px;
							width: 12px;
							left: 50%;
							position: absolute;
							transform: rotate(45deg) translateX(-50%);

							@include mq(tablet-down) {
								height: 19px;
								width: 19px;
							}
						}
					}


					&:last-child {
						@include mq(tablet-down) {
							margin-bottom: 0;
						}


						&:after {
							display: none;
						}
					}

					&:nth-child(odd) {
						margin-right: 0;
						margin-left: calc(50% + 75px);

						@include mq(tablet-down) {
							margin-left: 0;
						}


						.item-marker {
							right: auto;
							left: -91px;
						}

						&.mod-active .item-marker {
							left: -97px;
						}

						.wrap-content {
							align-items: flex-start;

							@include mq(tablet-down) {
								align-items: center;
								padding-bottom: 10px;
							}


							.sub-content-wrapper {
								p {
									text-align: left;

									@include mq(tablet-down) {
										font-size: 14px;
										letter-spacing: 0;
										line-height: 27px;
										max-width: 255px;
										text-align: center;
									}
								}
							}
						}

						.wrap-content:before {
							left: auto;
							right: 100%;
							border-left-color: rgba(0, 0, 0, 0);
							border-right-color: #ddddd9;

							@include mq(tablet-down) {
								display: none;
							}
						}
					}

					.item-marker {
						content: "";
						background: $color-white;
						border-radius: 50%;
						border: 1px solid $color-primary;
						box-sizing: content-box;
						height: 30px;
						position: absolute;
						right: -91px;
						top: 50%;
						width: 30px;

						&:before {
							background: $color-white;
							border-radius: 50%;
							border: 1px solid $color-primary;
							content: '';
							display: block;
							height: 18px;
							left: 50%;
							position: absolute;
							top: 50%;
							transform: translate(-50%, -50%);
							width: 18px;
						}

						@include mq(tablet-down) {
							display: none;
						}
					}

					&.mod-active .item-marker {
						width: 12px;
						height: 12px;
						border: 16px solid $color-black;
						top: 39px;
						right: -97px;
					}

					.wrap-content {
						align-items: flex-end;
						display: flex;
						flex-direction: column;
						padding: 12px 12px 30px;
						position: relative;

						@include mq(tablet-down) {
							align-items: center;
							padding-bottom: 10px;
						}


						.sub-content {
							p {
								text-align: right;

								@include mq(tablet-down) {
									text-align: center;
								}
							}
						}
					}

					.sub-image {
						background-size: cover;
						background-repeat: no-repeat;
						height: 162px;
						margin-bottom: 20px;
						width: 255px;

						@include mq(tablet-down) {
							width: 255px;
							margin: 0 auto 20px auto;
						}
					}

					.sub-title {
						h4 {
							color: $color-text-title;
							font-size: 25px;
							line-height: 30px;
							max-width: 220px;
							margin-bottom: 22px;
							width: 100%;

							@include mq(tablet-down) {
								font-size: 20px;
								font-weight: $font-weight-regular;
								letter-spacing: 0;
								line-height: 30px;
								text-align: center;
								margin-bottom: 12px;
								max-width: none;
							}
						}
					}

					.sub-content {
						margin: 0;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
							text-align: center;
						}


						p {
							color: #424242;
							font-size: 15px;
							line-height: 26px;
							margin: 0;
							padding: 0;
						}

						p:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.section-footer-text--mobile {
		display: none;

		@include mq(tablet-down) {
			display: block;
		}


		.sub-content {
			@extend %container;

			padding: 30px 0 50px 0;

			> h1 {
				color: #00303C;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				font-size: 22px;
				line-height: 27px;
				letter-spacing: 0.5px;
				margin-bottom: 23px;
				text-align: left;
			}

			> p {
				font-size: 14px;
				line-height: 27px;
				text-align: left;
			}
		}
	}

	.section-email-block {
		background-color: $color-secondary;

		.sub-content {
			@extend %container;

			padding: 98px 0 75px 0;
			text-align: center;

			@include mq(tablet-down) {
				padding: 50px 0;
			}


			> a {
				margin: 0 auto;
			}

			> h2 {
				color: $color-primary;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				line-height: 20px;
				margin: 0 0 30px 0;

				@include mq(tablet-down) {
					font-size: 18px;
					letter-spacing: 0;
					line-height: 33px;
					margin: 0 0 25px 0;
					text-align: center;
				}
			}
		}
	}
}
