#project {

	.grecaptcha-badge {
		display: none;
	}

	.section-project-hero {
		background-position: center;
		background-size: cover;
		margin: -103px 0 0 0;
		height: 100vh;
		width: 100%;

		@include mq(tablet-down) {
			height: 450px;
			margin-top: -67px;
		}

		@include mq(mobile) {
			height: 283px;
		}
	}

	.section-main-content {

		.sub-content {
			@extend %container;
			padding: 65px 0 112px 0;

			@include mq(tablet-down) {
				padding: 40px 0px;
			}

			ul.list-contents {
				@extend %list-default;
				margin: 0 auto;
				max-width: 650px;

				.slick-dots {
					bottom: -50px;
					display: flex;
					justify-content: center;
					position: absolute;
					width: 100%;

					li {
						margin-bottom: 18px;

						@include mq(tablet-down) {
							margin: 0 23.5px 0 0;

							&:last-child {
								margin: 0;
							}
						}

						button {
							background-color: none;
							background: none;
							border: 1px solid #979797;
							height: 11px;
							outline: none;
							opacity: 1;
							width: 11px;
						}

						&.slick-active {

							button {
								background: #D8D8D8;
								border: none;
							}
						}
					}
				}

				li.item-content {
					text-align: center;
					margin: 0;

					ul.list-content {
						@extend %list-default;

						li.item-content-text {
							color: $color-black;
							font-size: 18px;
							font-weight: $font-weight-bold;
							line-height: 33px;

							@include mq(tablet-down) {
								font-size: 14px;
								letter-spacing: 0;
								line-height: 29px;
								text-align: center
							}

							> span {
								font-weight: $font-weight-light;
								margin: 0;

								&:before {
									content: '|';
									margin: 0 15px;
								}
							}
						}
					}

					> h1 {
						color: $color-primary;
						font-size: 25px;
						font-weight: $font-weight-bold;
						letter-spacing: 0.6px;
						line-height: 20px;
						margin: 0 0 50px 0;

						@include mq(tablet-down) {
							font-size: 20px;
							letter-spacing: 0.36px;
							line-height: 20px;
							margin-bottom: 20px;
							text-align: center;
						}
					}

					p {
						color: $color-black;
						font-size: 18px;
						font-weight: $font-weight-semibold;
						line-height: 33px;
						font-style: italic;
						margin: 0;

						@include mq(tablet-down) {
							font-size: 14px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 27px;
							max-width: unset;
						}
					}

					p.testimonial-name {
						color: $color-black;
						font-size: 18px;
						font-weight: $font-weight-semibold;
						font-style: unset;
						margin: 20px 0 0 0;

						@include mq(tablet-down) {
							font-size: 14px;
							letter-spacing: 0;
							line-height: 27px;
							max-width: unset;
						}
					}
				}
			}
		}
	}

	.section-gallery {
		margin: 50px 0 93px 0;

		@include mq(tablet-down) {
			margin: 50px 0;
		}

		.sub-content {
			@extend %container;
			width: 98%;
			max-width: 1680px;

			@include mq(tablet-down) {
				margin: 0;
				width: 100%;
				max-width: unset;
			}

			ul.list-images {
				@extend %list-default;
				display: grid;
				grid-template-columns: [first] calc(16.6667% - 4px) [line2] calc(16.6667% - 4px) [line3] calc(16.6667% - 4px) [line4] calc(16.6667% - 4px) [line5] calc(16.6667% - 4px) [line6] calc(16.6667% - 4px);
				grid-template-rows: [first] 414px [row2] 414px [row3] 414px [row4] 414px [row5] 414px [row6] 414px;
				column-gap: 5px;
				row-gap: 5px;

				@include mq(tablet-down) {
					grid-template-rows: [first] 250px [row2] 250px [row3] 250px [row4] 250px [row5] 250px [row6] 250px;
				}

				@include mq(mobile) {
					grid-template-rows: [first] 156px [row2] 156px [row3] 156px [row4] 156px [row5] 156px [row6] 156px;
				}

				li.item-image {
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					height: 100%;
					max-height: 839px;

					&--0 {
						grid-column-start: 1;
						grid-column-end: 3;
						grid-row-start: 1;
						grid-row-end: 3;
					}

					&--1 {
						grid-column-start: 3;
						grid-column-end: 7;
						grid-row-start: 1;
						grid-row-end: 2;
					}

					&--2 {
						grid-column-start: 3;
						grid-column-end: 7;
						grid-row-start: 2;
						grid-row-end: 3;
					}

					&--3 {
						grid-column-start: 1;
						grid-column-end: 7;
						grid-row-start: 3;
						grid-row-end: 5;
					}

					&--4 {
						grid-column-start: 1;
						grid-column-end: 4;
						grid-row-start: 5;
						grid-row-end: 7;
					}

					&--5 {
						grid-column-start: 4;
						grid-column-end: 7;
						grid-row-start: 5;
						grid-row-end: 7;
					}

					&:nth-child(n+7) {
						width: 100%;
						height: 839px;
						grid-column-start: 1;
						grid-column-end: 7;

						@include mq(tablet-down) {
							height: 505px;
						}

						@include mq(mobile) {
							height: 317px;
						}
					}
				}
			}
		}
	}

	.section-email-block {
		background-color: $color-secondary;

		.sub-content {
			@extend %container;
			padding: 98px 0 75px 0;
			text-align: center;

			@include mq(tablet-down) {
				padding: 50px 0;
			}

			> a {
				margin: 0 auto;
			}

			> h2 {
				color: $color-primary;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				line-height: 20px;
				margin: 0 0 30px 0;

				@include mq(tablet-down) {
					font-size: 18px;
					letter-spacing: 0;
					line-height: 33px;
					margin: 0 0 25px 0;
					text-align: center;
				}
			}
		}
	}

	.section-other-sector {
		margin: 75px 0;
		overflow: hidden;
		width: 100%;

		@include mq(tablet-down) {
			margin: 50px 0;
		}

		.sub-content {
			@extend %container;
			position: relative;

			@include mq(tablet-down) {
				overflow: hidden;
			}

			div {
				overflow: unset;
			}

			h5 {
				align-items: center;
				color: $color-primary;
				display: flex;
				font-size: 20px;
				font-style: normal;
				font-weight: $font-weight-semibold;
				letter-spacing: 1.6px;
				margin-bottom: 20px;

				@include mq(tablet-down) {
					font-size: 18px;
					margin-bottom: 13px;
				}


				.btn {
					margin-left: auto;

					svg {
						color: #71898c;
					}
				}
			}

			.list-other-sectors {
				margin: 0;
				list-style-type: none;

				@include mq(tablet-down) {
					display: flex;
				}

				.slick-prev, .slick-next {
					color: $color-white;
					cursor: pointer;
					width: 24px;
					position: absolute;
					top: -39px;

					@include mq(tablet-down) {
						top: -30px;
					}
				}

				.slick-next {
					left: 210px;

					@include mq(tablet-down) {
						left: 216px;
					}
				}

				.slick-prev {
					left: 170px;

					@include mq(tablet-down) {
						left: 180px;
					}
				}

				.slick-disabled {
					cursor: default;
					opacity: 0.5;
				}

				.slick-slide {
					width: 395px;
				}

				.item-sector {
					align-items: center;
					background-color: rgba($color-primary, .6);
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					cursor: pointer;
					display: flex !important;
					height: 277px;
					justify-content: center;
					margin: 0;
					position: relative;

					@include mq(tablet-down) {
						align-items: flex-end;
						background-color: unset;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						margin-bottom: 11px;

						&:nth-child(n+5) {
							display: none;
						}
					}

					@include mq(mobile) {
						height: 199px;
					}

					&:hover {

						.item-information-overlay {
							background-color: rgba($color-white, 0.7);
						}

						.sector-title {
							color: $color-primary;
						}
					}

					.sub-link {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 99;
					}

					.item-information-overlay {
						background-color: $color-primary;
						display: flex;
						flex-direction: column;
						height: 277px;
						margin: 0;
						opacity: 0.6;
						padding: 30px 40px;
						position: absolute;
						width: 100%;

						@include mq(tablet-down) {
							display: none;
						}

						.project-info-sub-title {
							color: $color-white;
							font-size: 13px;
							letter-spacing: 1.3px;
							margin-bottom: 5px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								display: none;
							}
						}

						p {
							color: $color-white;
							font-size: $font-size-med;
							justify-self: flex-end;
							line-height: 29px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;

							@include mq(tablet-down) {
								line-height: $line-height-base;

								&:not(.project-info-sub-title) {
									display: none;
								}
							}
						}
					}

					.sector-title {
						color: $color-white;
						display: block;
						font-size: 19px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0.8px;
						margin: 0;
						position: relative;
						z-index: 9999;

						@include mq(tablet-down) {
							align-items: flex-start;
							background-color: rgba($color-primary, .6);
							display: flex;
							flex-direction: column;
							font-size: 18px;
							justify-content: center;
							letter-spacing: 0.54px;
							line-height: 16px;
							height: 65px;
							margin-bottom: 0;
							margin-top: -64px;
							max-width: unset;
							padding-left: 20px;
							width: 100%;
						}
					}

					.mod-show {
						display: flex;

						svg {
							animation: arrow-anim-right 1s;
						}
					}
				}
			}

			.sub-button-mobile {
				display: none;

				@include mq(tablet-down) {
					border: 1px solid #a7aba1;
					display: block;
					font-size: 12px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin: 53px auto;
					padding: 15px;
					text-align: center;
					text-transform: uppercase;
					width: 70%;
				}
			}
		}
	}
}
