#home {
	overflow-x: hidden;

	.grecaptcha-badge {
		display: none;
	}

	.header-main .sub-logo svg {
		color: $color-white;
	}

	.section-hero-gallery {
		height: 100vh;
		width: 100%;

		@include mq(tablet-down) {
			height: 512px;
			margin-top: -64px;
		}


		.list-home-hero-images {
			margin: 0;
			height: 100%;

			.item-home-hero-image {
				background-position: center;
				background-size: cover;
				display: flex;
				height: 100vh;
				margin: 0;

				@include mq(tablet-down) {
					height: 512px;
				}
			}

			.slick-list {
				padding: 0;
				height: 100%;
			}
		}
	}

	.section-main-copy {
		margin: 55px 0 70px 0;

		@include mq(tablet-down) {
			margin: 50px 0 30px;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: center;

			.sub-content-copy {
				width: calc(100% - 42.45%);

				@include mq(tablet-down) {
					width: 100%;
				}


				h1 {
					color: #00303C;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin-bottom: 36px;
					text-align: center;

					@include mq(tablet-down) {
						font-size: 22px;
						line-height: 27px;
						letter-spacing: 0.5px;
						margin-bottom: 23px;
						text-align: left;
					}
				}

				.sub-main-copy {
					p {
						color: $color-black;
						font-size: 18px;
						line-height: 33px;
						max-width: 699px;
						text-align: center;

						@include mq(tablet-down) {
							font-size: 14px;
							line-height: 27px;
							text-align: left;
						}


						strong {
							font-size: 18px;
							font-weight: $font-weight-regular;

							@include mq(tablet-down) {
								font-size: 14px;
								line-height: 27px;
								text-align: left;
							}
						}
					}
				}
			}
		}
	}

	.section-main-contracts {
		background-color: $color-secondary;
		padding: 36.5px 0;

		@include mq(tablet-down) {
			padding: 43px 0;
		}


		.sub-content {
			@extend %container;

			h1 {
				color: $color-text-title;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				margin-bottom: 36px;
				text-align: center;

				@include mq(tablet-down) {
					font-size: 20px;
					letter-spacing: 0.36px;
					line-height: 20px;
					text-align: left;
				}
			}

			.list-main-contracts {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
				}


				.item-main-contract {
					align-items: center;
					display: flex;
					height: 274px;
					justify-content: center;
					position: relative;
					width: calc((100% / 3) - 8px);

					@include mq(tablet-down) {
						align-items: flex-end;
						background-color: unset;
						background-repeat: no-repeat;
						background-size: cover;
						margin-bottom: 17px;
						width: calc((100% / 2) - 8px);
					}


					@include mq(mobile) {
						background-position: center;
						height: 246px;
						width: 100%;
					}


					&:nth-child(n+4) {
						margin-top: 11px;

						@include mq(tablet-down) {
							margin-top: 0;
						}
					}

					&:hover {
						align-items: flex-end;

						> h3 {
							display: none;
						}

						> a {
							background-color: unset;
						}

						.sub-learn-more {
							display: flex;
						}
					}

					.sub-learn-more {
						align-items: center;
						background-color: rgba($color-white, 0.7);
						color: $color-primary;
						display: none;
						font-size: 13px;
						font-weight: $font-weight-semibold;
						height: 44px;
						letter-spacing: 1.63px;
						padding-left: 34px;
						text-transform: uppercase;
						width: 100%;

						.btn-icon {
							.icon-container {
								> svg {
									color: #464646;
									margin: 7px 0 0 10px;
								}
							}
						}
					}

					> h3 {
						color: $color-white;
						font-size: 23px;
						font-weight: $font-weight-regular;
						margin: 0;
						max-width: 80%;
						text-align: center;
						z-index: 999;

						@include mq(tablet-down) {
							align-items: center;
							background-color: rgba($color-primary, 0.6);
							display: flex;
							font-size: 18px;
							justify-content: center;
							letter-spacing: 0.54px;
							line-height: 16px;
							height: 63.5px;
							max-width: unset;
							width: 100%;
						}
					}

					> a {
						background-color: rgba($color-primary, 0.6);
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;

						@include mq(tablet-down) {
							background-color: unset;
						}
					}
				}
			}
		}
	}

	.section-nav-blocks {
		padding: 36.5px 0 24.5px 0;

		.sub-content {
			@extend %container;

			.list-nav-blocks {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.item-nav-block {
					align-items: center;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					display: flex;
					height: 345px;
					justify-content: center;
					position: relative;
					width: calc((100% / 2) - 6px);

					@include mq(tablet-down) {
						align-items: flex-end;
						background-color: unset;
						margin-bottom: 17px;
						width: 100%;
					}


					@include mq(mobile) {
						height: 246px;
					}


					&:hover {
						align-items: flex-end;

						> h3 {
							display: none;
						}

						> a {
							background-color: unset;
						}

						.sub-learn-more {
							display: flex;
						}
					}

					.sub-learn-more {
						align-items: center;
						background-color: rgba($color-white, 0.7);
						color: $color-primary;
						display: none;
						font-size: 13px;
						font-weight: $font-weight-semibold;
						height: 44px;
						letter-spacing: 1.63px;
						padding-left: 34px;
						text-transform: uppercase;
						width: 100%;

						.btn-icon {
							.icon-container {
								> svg {
									color: #464646;
									margin: 7px 0 0 10px;
								}
							}
						}
					}

					&--large {
						margin: 11px 0;
						width: 100%;

						@include mq(tablet-down) {
							margin-top: 0;
						}
					}

					> h3 {
						color: $color-white;
						font-size: 23px;
						font-weight: $font-weight-regular;
						margin: 0;
						max-width: 80%;
						text-align: center;
						z-index: 999;

						@include mq(tablet-down) {
							align-items: center;
							background-color: rgba($color-primary, 0.6);
							display: flex;
							font-size: 18px;
							justify-content: center;
							letter-spacing: 0.54px;
							line-height: 16px;
							height: 63.5px;
							max-width: unset;
							width: 100%;
						}
					}

					> a {
						background-color: rgba($color-primary, 0.6);
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;

						@include mq(tablet-down) {
							background-color: unset;
						}
					}
				}
			}
		}
	}

	.section-home-projects {
		margin: 75px 0;

		@include mq(mobile) {
			margin: 60px 0 0 0;
		}


		.sub-content {
			@extend %container;

			position: relative;

			@include mq(tablet-down) {
				overflow: hidden;
			}


			div {
				overflow: unset;
			}

			h5 {
				align-items: center;
				color: $color-primary;
				display: flex;
				font-size: 20px;
				font-style: normal;
				font-weight: $font-weight-semibold;
				letter-spacing: 1.6px;
				margin-bottom: 20px;

				@include mq(tablet-down) {
					font-size: 18px;
					margin-bottom: 13px;
				}


				.btn {
					margin-left: auto;

					svg {
						color: #71898c;
					}
				}
			}

			.list-home-projects {
				margin: 0;
				list-style-type: none;

				@include mq(tablet-down) {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
				}


				.slick-prev, .slick-next {
					color: $color-white;
					cursor: pointer;
					width: 24px;
					position: absolute;
					top: -39px;

					@include mq(tablet-down) {
						top: -30px;
					}
				}

				.slick-next {
					left: 240px;

					@include mq(tablet-down) {
						left: 216px;
					}
				}

				.slick-prev {
					left: 200px;

					@include mq(tablet-down) {
						left: 180px;
					}
				}

				.slick-disabled {
					cursor: default;
					opacity: 0.5;
				}

				.slick-slide {
					width: 395px;
				}

				.item-project {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					cursor: pointer;
					height: 277px;
					margin: 0;
					position: relative;

					&:hover {
						.item-information-overlay {
							display: flex;
						}
					}

					@include mq(tablet-down) {
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;

						&:nth-child(n+5) {
							display: none;
						}
					}


					.sub-link {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 99;
					}

					.item-information-overlay {
						background-color: $color-primary;
						display: none;
						flex-direction: column;
						height: 277px;
						margin: 0;
						opacity: 0.8;
						padding: 40px 30px;
						position: absolute;
						width: 100%;

						@include mq(tablet-down) {
							background: $color-secondary;
							bottom: 0;
							display: flex !important;
							height: 104px;
							justify-content: center;
							opacity: 1;
							padding: 20px 25px;
						}


						.project-info-sub-title {
							color: $color-white;
							font-size: 13px;
							letter-spacing: 1.3px;
							margin-bottom: 5px;
							margin-top: 0;
							text-transform: uppercase;

							@include mq(tablet-down) {
								color: #5B6464;
								font-size: 10px;
								margin-bottom: 0;
							}
						}

						p {
							color: $color-white;
							font-size: $font-size-med;
							justify-self: flex-end;
							line-height: 29px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							font-weight: $font-weight-light;
							margin-top: auto;
							margin-bottom: 0;

							@include mq(tablet-down) {
								line-height: $line-height-base;

								&:not(.project-info-sub-title) {
									display: none;
								}
							}
						}

						> h5 {
							color: $color-white;
						}

						.sector-title {
							color: $color-white;
							display: block;
							font-size: 19px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0.8px;
							margin: 0;
							position: relative;
							z-index: 9999;

							@include mq(tablet-down) {
								align-items: flex-start;
								color: $color-primary;
								font-size: 17px;
								margin-bottom: 0;
							}
						}

						.mod-show {
							display: flex;

							svg {
								animation: arrow-anim-right 1s;
							}
						}
					}
				}
			}

			.sub-button-mobile {
				display: none;

				@include mq(tablet-down) {
					border: 1px solid #a7aba1;
					display: block;
					font-size: 12px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin: 53px auto;
					padding: 15px;
					text-align: center;
					text-transform: uppercase;
					width: 70%;
				}
			}
		}
	}

	.section-testimonials {
		.sub-content {
			@extend %container;

			display: flex;
			position: relative;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-copy {
				padding-right: 90px;
				padding-top: 10px;
				width: 50%;

				@include mq(tablet-down) {
					padding-right: 0;
					width: 100%;
				}


				.sub-copy-container {
					@include mq(tablet-down) {
						margin-bottom: 90px;
					}


					> h1 {
						color: $color-text-title;
						font-size: 28px;
						font-weight: $font-weight-bold;
						line-height: 38px;
						letter-spacing: 0.6px;
						margin-bottom: 36px;
						max-width: 359px;
						width: 100%;

						@include mq(tablet-down) {
							color: #00303C;
							font-size: 21px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0.5px;
							line-height: 27px;
							letter-spacing: 0.54px;
							max-width: 70%;
						}
					}

					> p {
						color: $color-black;
						font-size: 18px;
						line-height: 33px;
						max-width: 514px;

						@include mq(tablet-down) {
							font-size: 14px;
							letter-spacing: 0;
							line-height: 27px;
							max-width: unset;
						}


						strong {
							font-size: 18px;
							font-weight: $font-weight-regular;

							@include mq(tablet-down) {
								font-size: 14px;
								letter-spacing: 0;
								line-height: 27px;
								max-width: unset;
							}
						}
					}
				}
			}

			.sub-testimonials {
				background-color: $color-secondary;
				padding: 87px 0 87px 84px;
				width: 50%;

				&:after {
					content: "";
					background-color: #eeece6;
					height: 100%;
					width: 100%;
					position: absolute;
					right: -80px;
					top: 0;
					margin-left: 0;
					margin-right: -570px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				@include mq(tablet-down) {
					height: unset;
					padding: 60px 25px 57px 25px;
					width: 100%;
				}


				h1 {
					font-size: 22px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.53px;
					line-height: 38px;
					z-index: 99999;
					position: relative;
					z-index: 999;

					@include mq(tablet-down) {
						font-size: 21px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0.5px;
						line-height: 38px;
						margin-bottom: 30px;
					}
				}

				.list-testimonials {
					@extend %list-default;

					z-index: 999;

					li.item-testimonial {
						margin-left: 0;

						@include mq(tablet-down) {
							margin-bottom: 50px;
						}


						> p {
							font-size: 18px;
							font-style: italic;
							line-height: 33px;
							width: 100%;

							@include mq(tablet-down) {
								font-size: 14px;
								letter-spacing: 0;
								line-height: 33px;
							}
						}

						.testimonial-name {
							color: #00303C;
							font-size: 11px;
							font-style: unset;
							font-weight: $font-weight-bold;
							letter-spacing: 1.8px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								letter-spacing: 1.47px;
								line-height: 20px;
							}
						}
					}

					.slick-dots {
						bottom: 0;
						display: flex;
						left: 0;
						position: absolute;
						width: 0;

						li {
							margin-bottom: 18px;

							@include mq(tablet-down) {
								margin: 0 23.5px 0 0;
							}


							button {
								background-color: none;
								background: none;
								border: 1px solid #979797;
								height: 11px;
								outline: none;
								opacity: 1;
								width: 11px;
							}

							&.slick-active {
								button {
									background: #D8D8D8;
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
