ul.list-posts {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 70px 0 0 0;

	@include mq(tablet-down) {
		margin: 33px 0 0 0;
	}


	.item-post {
		margin: 0;
		position: relative;
		width: calc(100% / 3);

		@include mq(tablet-down) {
			width: 100%;
		}


		.sub-link {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			&:hover {
				& ~ .item-info {
					svg {
						animation: arrow-anim-right 1s;
					}

					a {
						display: flex;
					}

					p {
						display: none;
					}
				}
			}
		}

		.sub-image {
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			height: 212px;

			@include mq(tablet-down) {
				height: 196px;
			}
		}

		.item-info {
			background-color: $color-secondary;
			height: 143px;
			padding: 30px 40px;

			@include mq(tablet-down) {
				display: flex;
				flex-direction: column;
				height: 120px;
				justify-content: center;
				margin: 0;
				padding: 17px 25px;
				width: 100%;
			}


			p {
				color: #5B6464;
				font-size: 13px;
				letter-spacing: 1.73px;
				margin-bottom: 5px;
				text-transform: uppercase;

				@include mq(tablet-down) {
					display: none;
				}
			}

			h5 {
				color: #00303C;
				font-size: 19px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.8px;

				@include mq(tablet-down) {
					font-size: 17px;
					letter-spacing: 0.51px;
				}


				img {
					float: right;
					margin-top: 7px;

					@include mq(tablet-down) {
						width: 20px;
					}
				}
			}

			a {
				align-items: center;
				display: none;
				font-size: 13px;
				font-weight: $font-weight-bold;
				letter-spacing: 1.8px;
				text-transform: uppercase;
				margin-top: 20px;

				@include mq(tablet-down) {
					display: flex;
					margin-top: 0px;
					font-size: 11px;
				}


				svg {
					color: #71898c;
					margin-left: 20px;
				}

				.btn {
					display: flex;
				}
			}
		}

		.item-info:hover {
			a {
				display: flex;
			}

			p {
				display: none;
			}

			h5 {
				margin-bottom: 0;

				@include mq(tablet-down) {
					margin-bottom: 30px;
				}
			}
		}
	}
}
