#joinery {

	.grecaptcha-badge {
		display: none;
	}

	.section-joinery {
		margin-top: 60px;

		@include mq(tablet-down) {
			margin-top: 40px;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-main-copy-wrapper {
				@extend %common-left-50-wide;
			}

			.sub-copy-image-wrapper {
				flex: 1;

				@include mq(tablet-down) {
					width: 100%;
				}
			}
		}

		.joinery-copy-image {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 460px;
			margin-bottom: 50px;

			&.mod-mobile {
				display: none;

				@include mq(tablet-down) {
					display: block;
					height: 50vw;
				}
			}

			&.mod-desktop {
				@include mq(tablet-down) {
					display: none;
				}
			}
		}

		.joinery-link-box {
			background-color: $color-secondary;
			margin-bottom: 40px;

			@include mq(tablet-down) {
				margin-bottom: 30px;
			}


			&.mod-mobile {
				display: none;

				@include mq(tablet-down) {
					display: block;
				}
			}

			&.mod-desktop {
				@include mq(tablet-down) {
					display: none;
				}
			}

			a {
				align-items: center;
				color: $color-text-title;
				display: flex;
				justify-content: space-between;
				font-size: 20px;
				font-weight: bold;
				padding: 32px 36px;

				@include mq(tablet-down) {
					font-size: 13px;
					padding: 20px 16px;
				}


				.btn {
					display: flex;
					margin-left: 20px;

					svg {
						color: #71898c;
					}
				}

				&:hover {
					svg {
						animation: arrow-anim-right 1s;
					}
				}
			}
		}
	}

	.section-meet-team {
		margin: 90px auto 95px auto;

		@include mq(tablet-down) {
			margin: 80px auto;
		}


		.sub-content {
			@extend %container;

			.sub-title {
				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					margin-bottom: 55px;

					@include mq(tablet-down) {
						display: flex;
						flex-direction: column-reverse;
						font-size: 18px;
					}


					span {
						color: #5B6464;
						font-size: 13px;
						font-weight: $font-weight-regular;
						letter-spacing: 1.6px;
						margin-left: 30px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							font-size: 10px;
							margin: 0 0 8px 0;
						}
					}
				}
			}

			.sub-team {
				.list-team {
					display: flex;
					flex-wrap: wrap;
					list-style-type: none;
					margin: 0;
					position: relative;

					.item-team {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						height: 240px;
						margin: 0 0 30px 0;
						overflow: hidden;
						width: calc(100% / 4);

						@include mq(tablet-down) {
							height: 221px;
							margin-bottom: 79px;
							overflow: unset;
							position: relative;
							width: 100%;
						}


						.team-info {
							background-color: $color-secondary;
							bottom: -247px;
							padding: 20px 25px;
							position: relative;

							@include mq(tablet-down) {
								bottom: -79px;
								height: 79px;
								margin: 0;
								padding: 17px 25px;
								position: absolute;
								width: 100%;
							}


							p {
								color: #5B6464;
								font-size: 13px;
								font-weight: $font-weight-regular;
								letter-spacing: 1.6px;
								margin-bottom: 5px;
								text-transform: uppercase;

								@include mq(tablet-down) {
									font-size: 10px;
								}
							}

							h5 {
								font-size: 20px;
								font-weight: $font-weight-bold;
								margin: 0;

								@include mq(tablet-down) {
									font-size: 17px;
								}
							}
						}
					}

					.item-team:hover {
						.team-info {
							bottom: -147px;

							@include mq(tablet-down) {
								bottom: -79px;
							}
						}
					}
				}
			}
		}
	}

	.section-joinery-gallery {
		height: 539px;
		width: 100%;

		.list-joinery-images {
			height: 100%;
			list-style-type: none;
			margin: 0;

			.item-joinery-image {
				background-position: center;
				background-size: cover;
				display: flex;
				height: 539px;
				margin: 0;
				width: 100vw !important;
			}

			.slick-dots {
				background-color: rgba(255, 255, 255, 0.5);
				position: absolute;
				bottom: 0;
				display: flex;
				width: 100%;

				li {
					cursor: pointer;
					flex: 1;
					flex-shrink: 0;
					height: 7px;
					margin: 0;
					padding: 0;

					button {
						background: rgba($color-white, 0.8);
						border: none;
						border-radius: 0;
						text-decoration: none;
						text-indent: -99999px;
						width: 100%;
						padding: 0;
						height: 7px;
						transition: background .2s ease;
						outline: none;
						cursor: pointer;
					}

					&.slick-active button {
						background-color: $color-primary;
						opacity: 0.8;
					}

					&.slick-active button:hover {
						background-color: $color-primary;
						opacity: 0.8;
					}

					button:hover {
						background-color: $color-primary;
						opacity: 0.4;
					}
				}
			}

			.slick-list {
				height: 100%;
				padding: 0;
			}
		}
	}
}
